body {
    margin: 0;
    background: #eeeeee;
}

@font-face {
    font-family: 'Gilmer Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Regular'), url('../assets/fonts/gilmer-regular.woff') format('woff');
}
@font-face {
    font-family: 'Gilmer Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Bold'), url('../assets/fonts/gilmer-bold.woff') format('woff');
}
@font-face {
    font-family: 'Gilmer Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Heavy'), url('../assets/fonts/gilmer-heavy.woff') format('woff');
}
@font-face {
    font-family: 'Gilmer Light';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Light'), url('../assets/fonts/gilmer-light.woff') format('woff');
}
@font-face {
    font-family: 'Gilmer Outline';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Outline'), url('../assets/fonts/gilmer-outline.woff') format('woff');
}
@font-face {
    font-family: 'Gilmer Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gilmer Medium'), url('../assets/fonts/gilmer-medium.woff') format('woff');
}

html,
body,
#root,
.content-flex {
    height: 100%;
    overflow: hidden;
    font-family: Roboto, Arial, Sans-serif;
}

.content-flex {
    display: flex;
    overflow: hidden;
}

.content-width {
    flex: 1;
    overflow-y: auto;
}

a {
    color: white;
    text-decoration: none;
}

label {
    display: flex;
    align-items: center;
}

/* date pickers */

.MuiPickersCalendarHeader-dayLabel,
.DayPicker-Weekday {
    color: rgba(0, 0, 0, 0.87) !important;
    font-weight: bold !important;
}

.MuiPickersDay-daySelected {
    background-color: #4a90e2 !important;
}

/* placeholders */

input::placeholder,
textarea::placeholder {
    opacity: 1 !important;
    color: #333 !important;
    font-size: 12px !important;
    font-family: Roboto, Arial, Sans-serif !important;
}
textarea {
    font-family: Roboto, Arial, Sans-serif !important;
    font-size: 12px !important;
}
em {
    color: #333 !important;
}
@media print {
    .orderList,
    #orderList,
    .orderTable {
        margin-top: 0;
        page-break-before: always;
        page-break-inside: always;
        page-break-after: always;
    }
    /* thead,
    #headers {
        display: block;
    } */
    tfoot,
    #footers {
        display: table-row;
    }
    button,
    .button {
        display: none !important;
    }
}

.orderTableData {
    background-color: white;
}
.orderTableData:nth-child(odd) {
    background-color: #c2c2c2;
}
pdfdiv {
    page-break-after: always;
    page-break-before: always;
}

b,
strong {
    font-weight: 500 !important;
}

.rrt-title {
    font-size: 14px !important;
    margin-bottom: 0 !important;
}
.rrt-text {
    font-size: 14px;
}
